'use client'

import { loginDialog } from '@store/login'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'

export default function useLoginDialog() {

  const [loginDialogState, setLoginDialogState] = useRecoilState(loginDialog)

  const showLoginDialog = useCallback(() => {
    setLoginDialogState(d => ({ ...d, visible: true, showLogin: true, showRegister: false }))
  }, [setLoginDialogState])

  const showRegisterDialog = useCallback(() => {
    setLoginDialogState(d => ({ ...d, visible: true, showLogin: false, showRegister: true }))
  }, [setLoginDialogState])

  const closeDialog = useCallback(() => {
    setLoginDialogState(d => ({ ...d, visible: false, showLogin: false, showRegister: false }))
  }, [setLoginDialogState])

  return {
    ...loginDialogState,
    showLoginDialog,
    showRegisterDialog,
    closeDialog,
  }
}
'use client'

import Button from '@component/form/button'
import Input from '@component/form/input'
import HttpProxy from '@httpProxy'
import IconClose from '@icon/close-border.svg'
import clsx from 'clsx'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useBoolean, useMount, useSessionStorage } from 'react-use'

export default function EnquiryBar() {

  // 是否浮动bar
  const [storageVisible, toggleStorage] = useSessionStorage('enquiry-bar-visible', false)
  const [visible, handleToggle] = useBoolean(false)
  function handleClose() {
    handleToggle(false)
    toggleStorage(false)
  }

  // 咨询次数
  const [storageEnquiryCount, handleStorageEnquiryCount] = useSessionStorage('enquiry-bar-enquiry-count', 0)
  const [enquiryCount, handleSetEnquiryCount] = useState(0)
  const disbaleEnquiry = enquiryCount >= 3
  function handleEnquired() {
    handleSetEnquiryCount(enquiryCount + 1)
    handleStorageEnquiryCount(enquiryCount + 1)
  }

  useMount(() => {
    handleToggle(storageVisible)
    handleSetEnquiryCount(storageEnquiryCount)
  })

  const form = useForm()
  const {
    handleSubmit,
    formState: { isSubmitting }
  } = form

  const [error, setError] = useState('')
  async function onSubmit(data) {
    try {
      setError('')
      await HttpProxy.create().post('/enquiry', data)
      handleEnquired()
      setError('提交成功，我们会尽快与您联系！')
    } catch (error) {
      setError('提交失败，请稍后再试！')
    }
  }

  return (
    <section className={clsx('bg-primary-dark', { 'sticky bottom-0 z-30': visible })}>
      {
        visible && (
          <div className='relative'>
            <button
              className='absolute border-[10px] border-transparent top-0 right-0 sm:right-20'
              onClick={handleClose}
              aria-label='close enquiry bar'
            >
              <IconClose className='block fill-white w-20 h-20 sm:w-32 sm:h-32' />
            </button>
          </div>
        )
      }
      <div className='section-wrap py-20 text-white'>
        <div className='flex flex-col sm:items-center sm:flex-row gap-x-10 gap-y-4'>
          <strong className='text-4xl'>了解更多落地项目</strong>
          <span className='text-balance'>请填写以下信息，众淘软件即刻与您联系！</span>
        </div>
        {
          error && (
            <div className='mt-10 text-red-500 text-sm'>{error}</div>
          )
        }
        <form className='mt-20 flex flex-col flex-wrap gap-x-20 gap-y-10 sm:flex-row' onSubmit={handleSubmit(onSubmit)}>
          <Input
            placeholder='请输入您的姓名'
            required
            name='name'
            form={form}
            wrapClassName='sm:flex-1'
          />
          <Input
            placeholder='请填写您的企业/组织名称'
            wrapClassName='sm:flex-1'
            required
            name='companyName'
            form={form}
          />
          <Input
            placeholder='请输入您的手机号'
            wrapClassName='sm:flex-1'
            required
            pattern={{ value: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' }}
            name='phone'
            form={form}
          />
          <Button
            type='submit'
            className='w-full min-w-140 sm:w-auto'
            loading={isSubmitting}
            disabled={disbaleEnquiry}
          >
            提交
          </Button>
        </form>
      </div>
    </section>
  )
}
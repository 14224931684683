'use client'

import { useCallback, useEffect } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { isFunction } from 'lodash-es'
import { useLocation } from 'react-use'

export default function useRouterChange(
  routeChangeStart,
  routeChangeComplete
) {

  function findClosestAnchor(element) {
    while (element && element.tagName.toLowerCase() !== 'a') {
      element = element.parentElement
    }
    return element
  }
  function isAnchorOfCurrentUrl(currentUrl, newUrl) {
    const currentUrlObj = new URL(currentUrl)
    const newUrlObj = new URL(newUrl)
    if (
      currentUrlObj.hostname === newUrlObj.hostname &&
      currentUrlObj.pathname === newUrlObj.pathname &&
      currentUrlObj.search === newUrlObj.search
    ) {
      const currentHash = currentUrlObj.hash
      const newHash = newUrlObj.hash
      return (
        currentHash !== newHash && currentUrlObj.href.replace(currentHash, '') === newUrlObj.href.replace(newHash, '')
      )
    }
    return false
  }

  const callStart = useCallback(() => {
    isFunction(routeChangeStart) && routeChangeStart()
  }, [routeChangeStart])

  const callComplete = useCallback(() => {
    isFunction(routeChangeComplete) && routeChangeComplete()
  }, [routeChangeComplete])

  const handleClick = useCallback(event => {
    try {
      const anchor = findClosestAnchor(event.target)
      if (anchor) {
        const currentUrl = window.location.href
        const newUrl = anchor.href
        const isExternalLink = anchor.target === '_blank'
        const isAnchor = isAnchorOfCurrentUrl(currentUrl, newUrl)

        /**
         * 指定不执行进度条 data-noprogress
         * event.metaKey mac上command按钮
         * event.ctrlKey window上ctrl按钮
         */
        if (anchor.getAttribute('data-noprogress') || event.target.getAttribute('data-noprogress')) return
        if (newUrl === currentUrl || isAnchor || isExternalLink || event.metaKey || event.ctrlKey) {
          callStart()
          callComplete()
        } else {
          callStart()
        }
      }
    } catch (error) {
      callStart()
      callComplete()
    }
  }, [callStart, callComplete])

  const pathname = usePathname()
  const searchParams = useSearchParams()
  const location = useLocation()
  useEffect(() => {
    callComplete()
  }, [pathname, location, searchParams, callComplete])

  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [handleClick])
}
'use client'

import clsx from 'clsx'
import { useMemo } from 'react'

export default function Input({
  type = 'text',
  placeholder = '',
  className = '',
  wrapClassName = '',
  prefix = null,
  suffix = null,
  PrefixIcon,
  name = '',
  form,
  required = false,
  pattern = null,
  minLength = null,
  maxLength = null,
  autoFocus = false
}) {


  const register = useMemo(() => {
    if (!form) return () => ({})
    return form.register
  }, [form])
  const error = form?.formState.errors[name]
  return (
    <div className={wrapClassName}>
      <div
        className={clsx(
          'h-40 rounded-sm bg-white text-sm px-10 border flex items-center gap-x-10',
          { 'border-red-500': !!error },
          className
        )}
      >
        {prefix}
        {
          PrefixIcon && <PrefixIcon className={clsx('w-22 h-22 flex-shrink-0', !!error ? 'fill-red-500' : 'fill-primary-dark')} />
        }
        <input
          type={type}
          placeholder={placeholder}
          className='w-full h-full outline-none'
          autoFocus={autoFocus}
          {...register(name, {
            required: required ? placeholder || true : undefined,
            pattern,
            minLength,
            maxLength
          })}
        />
        {suffix}
      </div>
      {
        error && (
          <div className='text-red-500 text-xs mt-5'>{error.message}</div>
        )
      }
    </div>
  )
}
import { TOKEN_KEY, UUID_KEY } from '@config'
import Cookies from 'js-cookie'
export default class HttpProxy {
  constructor(header, options) {
    this.header = Object.assign({
      'Content-Type': 'application/json',
      'uuid': Cookies.get(UUID_KEY)
    }, header)
    this.options = options
  }

  static create(header, options) {
    return new HttpProxy(header, options)
  }

  async #fetch(method, url, params) {
    if (!url) return Promise.reject('url is required')

    const u = new URL(`${url}`.replace(/^\/+/, ''), process.env.NEXT_PUBLIC_API_BASE)

    const token = typeof localStorage === 'object' ? localStorage.getItem(TOKEN_KEY) : null
    const headers = new Headers(Object.assign({}, this.header, token ? { 'Authorization': token } : {}))
    const isGet = method === 'GET'
    let body
    if (!isGet) {
      body = JSON.stringify(params) || '{}'
    }

    if (isGet && params) {
      for (const key in params) {
        u.searchParams.append(key, params[key])
      }
    }

    const response = await fetch(u, {
      method,
      headers,
      body,
      ...this.options
    })

    if (response.ok) {
      try {
        return await response.clone().json()
      } catch (error) {
        try {
          return await response.text()
        } catch (error) {
          return undefined
        }
      }
    }

    let data = {}
    try {
      data = await response.json()
    } catch (error) { }

    return Promise.reject({ status: response.status, statusText: response.statusText, ...data })
  }

  get(url, params) {
    return this.#fetch('GET', url, params)
  }

  post(url, params) {
    return this.#fetch('POST', url, params)
  }
}
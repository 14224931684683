'use client'

import Login from '@component/login'
import useLoginDialog from '@hooks/useLoginDialog'
import useLoginState from '@hooks/useLoginState'

export default function HeaderAvatar() {
  return (
    <>
      <AvatarContent />
      <Login />
    </>
  )
}

function AvatarContent() {
  const { showLoginDialog } = useLoginDialog()
  const { isLoad, info, handleLogout } = useLoginState()

  if (!isLoad) return null

  if (info) return (
    <div className='flex items-center text-xs sm:text-sm'>
      <span>你好, {info.userName}</span>
      <span className='text-primary-dark font-semibold ml-10 cursor-pointer' onClick={handleLogout}>[退出]</span>
    </div>
  )

  return (
    <div className='flex items-center'>
      <button className='text-sm text-primary-dark font-semibold' onClick={showLoginDialog}>登录/注册</button>
    </div>
  )
}

'use client'

import { REFRESH_TOKEN_KEY, TOKEN_KEY, USER_KEY, UUID_KEY } from '@config'
import login from '@store/login'
import { useCallback } from 'react'
import { useBoolean, useMount } from 'react-use'
import { useRecoilState } from 'recoil'
import Cookies from 'js-cookie'

export default function useLoginState() {

  const [isLoad, toggleLoad] = useBoolean(false)
  const [loginState, setLoginState] = useRecoilState(login)

  const handleSaveToken = useCallback((token, refreshToken) => {
    setLoginState(d => ({ ...d, token, refreshToken }))
    localStorage.setItem(TOKEN_KEY, token)
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  }, [setLoginState])

  const handleSaveUser = useCallback((info) => {
    setLoginState(d => ({ ...d, info }))
    localStorage.setItem(USER_KEY, JSON.stringify(info))

    // 保存一年
    const now = new Date()
    now.setFullYear(now.getFullYear() + 1)
    Cookies.set(UUID_KEY, info.uuid, { expires: now })
  }, [setLoginState])

  const handleLogout = useCallback(() => {
    setLoginState({ info: null, token: null, refreshToken: null })
    Cookies.remove(UUID_KEY)
    // 这里删除了cookie里的uuid 必须强制刷新页面，否则会导致聊天室的uuid不一致
    window.location.reload()
  }, [setLoginState])

  useMount(() => {
    toggleLoad(true)
  })

  return {
    isLoad,
    ...loginState,
    handleSaveToken,
    handleSaveUser,
    handleLogout
  }
}
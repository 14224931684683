import { IS_SERVER, REFRESH_TOKEN_KEY, TOKEN_KEY, USER_KEY } from '@config'
import { atom } from 'recoil'

const login = atom({
  key: 'login',
  default: {
    info: null,
    token: null,
    refreshToken: null,
  },
  effects: [
    ({ setSelf, onSet }) => {
      if (IS_SERVER) return

      onSet(newVal => {
        if (newVal.token) {
          localStorage.setItem(TOKEN_KEY, newVal.token)
        } else {
          localStorage.removeItem(TOKEN_KEY)
        }
        if (newVal.refreshToken) {
          localStorage.setItem(REFRESH_TOKEN_KEY, newVal.refreshToken)
        } else {
          localStorage.removeItem(REFRESH_TOKEN_KEY)
        }
        if (newVal.info) {
          localStorage.setItem(USER_KEY, JSON.stringify(newVal.info))
        } else {
          localStorage.removeItem(USER_KEY)
        }
      })

      const token = localStorage.getItem(TOKEN_KEY)
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)
      const info = JSON.parse(localStorage.getItem(USER_KEY))

      if (token && refreshToken && info) {
        setSelf(d => ({ ...d, info, token, refreshToken }))
      }
    }
  ]
})

const loginDialog = atom({
  key: 'loginDialog',
  default: {
    visible: false,
    showLogin: false,
    showRegister: false,
  }
})

export default login
export {
  loginDialog
}
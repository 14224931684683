'use client'

import AppendRoot from '@component/appendRoot'
import useLoginDialog from '@hooks/useLoginDialog'
import IconClose from '@icon/close.svg'
import clsx from 'clsx'
import SmsLogin from './sms'

export default function Login() {
  const { visible, showLogin, showRegister, closeDialog } = useLoginDialog()

  return (
    <AppendRoot>
      <dialog open={visible} autoFocus={visible} className={clsx(
        'z-[110] fixed inset-0 p-20 w-full h-full bg-black bg-opacity-40 backdrop-blur-sm flex items-center justify-center',
        visible ? 'opacity-100' : 'opacity-0 pointer-events-none'
      )}>
        <div className='bg-white shadow-xl -translate-y-1/4 w-540 max-w-full rounded-lg md:rounded-2xl relative p-20 md:px-60 md:py-30'>
          <div className='text-center relative'>
            <strong className='text-4xl text-secondary font-normal'>登录</strong>
            <button onClick={closeDialog} className='absolute right-0' aria-label='close dialog'>
              <IconClose className='block w-26 h-26 hover:rotate-180 transition-transform' />
            </button>
          </div>
          <SmsLogin />
        </div>
      </dialog>
    </AppendRoot>
  )
}

'use client'

import AppendRoot from '@component/appendRoot'
import IconArrow from '@icon/arrow-right.svg'
import IconClose from '@icon/close.svg'
import { URLS } from '@router'
import clsx from 'clsx'
import Link from 'next/link'
import { useToggle } from 'react-use'

export default function Menu() {

  const [showMenu, toggleMenu] = useToggle(false)
  return (
    <>
      <div className='self-center md:hidden text-black'>
        <button
          className={clsx('h-16 w-24 menu-btn', { 'open': showMenu })}
          aria-label='menu'
          onClick={toggleMenu}
        >
          <i />
        </button>
      </div>
      <AppendRoot>
        <HeaderPopup visible={showMenu} onClose={() => toggleMenu(false)} />
      </AppendRoot>
    </>
  )
}

function HeaderPopup({
  visible = false,
  onClose
}) {
  return (
    <div
      className={clsx(
        'fixed inset-0 z-[101] bg-black bg-opacity-40 backdrop-blur-sm transition-all',
        visible ? ' opacity-100 visible' : 'opacity-0 invisible pointer-events-none',
      )}
    >
      <div className={clsx('w-3/4 h-full flex flex-col bg-white relative transition-transform', visible ? '' : '-translate-x-full')}>
        <div className='flex items-center justify-between pl-20 pr-10 bg-primary-dark'>
          <strong className='text-white text-md'>众淘软件</strong>
          <button className='border-[10px] border-transparent' onClick={onClose}>
            <IconClose className='w-20 h-20 fill-white block' />
          </button>
        </div>
        <nav className='flex-1 min-h-0 overflow-auto bg-primary bg-opacity-5 py-20'>
          <ul className='space-y-1' onClick={onClose}>
            <NavItem href={URLS.homepage}>首页</NavItem>
            <NavItem href={URLS.cooperation}>合作共赢</NavItem>
            <NavItem href={URLS.custom}>高端定制</NavItem>
            <NavItem href={URLS.product}>产品介绍</NavItem>
            <NavItem href={URLS.staffOutsourcing}>人力外派</NavItem>
            <NavItem href={URLS.about}>关于我们</NavItem>
            <NavItem href={URLS.contact}>联系我们</NavItem>
          </ul>
        </nav>
      </div>
    </div>
  )
}

function NavItem({
  href,
  children
}) {
  return (
    <li className='border-t border-t-gray-bg bg-white'>
      <Link href={href} className='flex items-center justify-between py-10 px-20 text-primary-dark'>
        {children}
        <IconArrow className='fill-primary-dark w-18 h-18' />
      </Link>
    </li>
  )
}

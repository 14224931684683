'use client'

import Button from '@component/form/button'
import Input from '@component/form/input'
import useLoginDialog from '@hooks/useLoginDialog'
import useLoginState from '@hooks/useLoginState'
import HttpProxy from '@httpProxy'
import IconPhone from '@icon/phone.svg'
import IconCode from '@icon/code.svg'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

const countdownCount = 60
export default function SmsLogin() {

  const { handleSaveToken, handleSaveUser } = useLoginState()
  const { closeDialog } = useLoginDialog()

  const [sending, setSending] = useState(false)
  const [countdown, setCountdown] = useState(countdownCount)
  const [timer, setTimer] = useState(null)

  const [tips, setTips] = useState('')
  const [error, setError] = useState('')
  const form = useForm()
  const {
    handleSubmit,
    getValues,
    formState: { isSubmitting },
    trigger
  } = form

  async function onSubmit(data) {
    setError('')
    try {
      const result = await HttpProxy.create().post('/user/login-sms', { ...data })
      handleSaveToken(result.token, result.refreshToken)
      const user = await HttpProxy.create().get('/user/info')
      handleSaveUser(user)
      closeDialog()
    } catch (error) {
      setError(error.message)
    }
  }

  async function handleSendCode() {
    if (!await trigger('phone')) return
    const { phone } = getValues()
    setSending(true)
    await HttpProxy.create().get('/common/send-sms', { phone }).finally(() => setSending(false))
    startCountdown()
    setTips('验证码已发送，请注意查收')
  }

  function startCountdown() {
    const timer = setInterval(() => {
      setCountdown((v) => {
        if (v <= 0) {
          clearInterval(timer)
          setTimer(null)
          setSending(false)
          return countdownCount
        }
        return v - 1
      })
    }, 1000)
    setTimer(timer)
  }

  return (
    <form className='pt-40 pb-20 flex flex-col gap-y-20' onSubmit={handleSubmit(onSubmit)}>
      {
        tips && (
          <div className='text-green-600 text-sm'>{tips}</div>
        )
      }
      <Input
        placeholder='请输入手机号'
        PrefixIcon={IconPhone}
        name='phone'
        required
        pattern={{ value: /^1[3456789]\d{9}$/, message: '请输入正确的手机号' }}
        form={form}
        suffix={
          <Button className={clsx('!h-30 min-w-92 flex-shrink-0 whitespace-nowrap', { '!bg-gray-400': Boolean(timer) })} onClick={handleSendCode} loading={sending} disabled={Boolean(timer)}>
            {
              timer ? `${countdown}秒后重新获取` : '获取验证码'
            }
          </Button>
        }
      />
      <Input
        placeholder='请输入验证码'
        PrefixIcon={IconCode}
        name='code'
        required
        pattern={{ value: /^\d{6}$/, message: '请输入6位验证码' }}
        form={form}
      />
      <div>
        {
          error && (
            <p className='text-red-500 text-xs mb-4'>{error}</p>
          )
        }
        <Button type='submit' className='w-full' loading={isSubmitting}>登录</Button>
      </div>
    </form>
  )
}
import { atom } from 'recoil'

const chatbot = atom({
  key: 'chatbot',
  default: {
    show: false,
    spread: false,
    defaultText: ''
  }
})

export default chatbot
'use client'

import useChatbot from '@hooks/useChatbot'
import dynamic from 'next/dynamic'
import { useEffect } from 'react'
const ChatbotContent = dynamic(() => import('./content'), { ssr: false })

export default function Chatbot() {
  const { show, handleShow } = useChatbot()

  useEffect(() => {
    handleShow()
  }, [handleShow])

  return show && <ChatbotContent />
}
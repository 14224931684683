'use client'

import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

export default function NavItem({
  children,
  href
}) {
  const pathname = usePathname()
  const isActive = useMemo(() => {
    const curUrl = new URL(href, process.env.NEXT_PUBLIC_DOMAIN)
    return curUrl.pathname === pathname
  }, [pathname, href])

  return (
    <li>
      <Link
        href={href}
        className={clsx(
          'block h-40 relative px-2 after:absolute after:inset-x-0 after:bottom-0 after:h-4 after:rounded-sm after:bg-primary',
          'transition-colors duration-300 after:transition-opacity hover:text-primary hover:font-bold',
          'text-sm lg:text-base xl:text-base whitespace-nowrap',
          isActive ? 'text-primary font-bold' : 'after:opacity-0'
        )}
      >
        {children}
      </Link>
    </li>
  )
}
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/logo.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/qrcode.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/src/components/chatbot/index.js");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/src/components/devsiteProgress/index.js");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/src/components/enquiryBar/index.js");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/src/components/header/avatar.js");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/src/components/header/menu.js");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/src/components/header/navItem.js");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/src/components/imageViewer/index.js")
import imageViewer from '@store/imageViewer'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'

export default function useImageViewer() {
  const [imageViewerState, setImageViewer] = useRecoilState(imageViewer)

  const showImages = useCallback((images, idx = 0) => {
    setImageViewer({
      show: true,
      images,
      index: idx
    })
  }, [setImageViewer])

  const hideImages = useCallback(() => {
    setImageViewer({
      show: false,
      images: [],
      index: 0
    })
  }, [setImageViewer])

  return {
    ...imageViewerState,
    showImages,
    hideImages
  }
}
import chatbot from '@store/chatbot'
import { isBoolean } from 'lodash-es'
import { useCallback } from 'react'
import { useRecoilState } from 'recoil'

export default function useChatbot() {
  const [{ spread, show, defaultText }, setChatbotState] = useRecoilState(chatbot)
  const toggleSpread = useCallback((spread, defaultText) => {
    setChatbotState(d => ({ ...d, spread: isBoolean(spread) ? spread : !d.spread, defaultText }))
  }, [setChatbotState])
  const handleShow = useCallback(() => {
    setChatbotState(d => ({ ...d, show: true }))
  }, [setChatbotState])

  const setDefaultText = useCallback((text) => {
    setChatbotState(d => ({ ...d, defaultText: text }))
  }, [setChatbotState])

  return {
    show,
    spread,
    defaultText,
    toggleSpread,
    handleShow,
    setDefaultText
  }
}
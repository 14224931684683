'use client'

import AppendRoot from '@component/appendRoot'
import useImageViewer from '@hooks/useImageViewer'
import clsx from 'clsx'
import { map } from 'lodash-es'
import Image from 'next/image'
import 'swiper/css'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss/navigation'
import IconClose from '@icon/close.svg'

export default function ImageViewer() {

  const { show, images, hideImages } = useImageViewer()
  return (
    <AppendRoot>
      <dialog
        open={show}
        autoFocus={show}
        className={clsx(
          'z-[110] fixed inset-0 p-20 w-full h-full bg-black bg-opacity-40 backdrop-blur-sm flex items-center justify-center',
          show ? 'opacity-100' : 'opacity-0 pointer-events-none'
        )}
      >
        <button className='border-[10px] border-transparent absolute right-20 top-20 group/close' onClick={hideImages}>
          <IconClose className='w-30 h-30 fill-white block duration-300 group-hover/close:rotate-180' />
        </button>
        <div className='w-full h-[80vh]'>
          {
            show && (
              <Swiper
                className='h-full'
                modules={[Navigation]}
                navigation
              >
                {
                  map(images, (image, index) => (
                    <SwiperSlide key={index} className='!flex items-center justify-center'>
                      <Image width={1000} height={800} src={image} alt={index} className='w-auto h-auto max-w-full max-h-full block mx-auto' />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            )
          }
        </div>
      </dialog>
    </AppendRoot>
  )
}
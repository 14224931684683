import { atom } from 'recoil'

const imageViewer = atom({
  key: 'imageViewer',
  default: {
    show: false,
    index: 0,
    images: []
  }
})

export default imageViewer
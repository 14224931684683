import clsx from 'clsx'
import IconLoading from '@icon/const/loading.svg?url'
import Image from 'next/image'

export default function Button({
  type = 'button',
  className = '',
  children,
  loading = false,
  disabled = false,
  onClick
}) {

  const disable = loading || disabled
  return (
    <button
      type={type}
      className={clsx(
        'flex items-center justify-center gap-x-6 h-40 rounded-sm transition-colors bg-primary-dark text-white text-sm px-10 border border-white',
        { 'cursor-not-allowed': disable },
        { 'active:bg-primary-dark-pressed hover:bg-primary-dark-light': !disable },
        className
      )}
      disabled={disable}
      onClick={onClick}
    >
      {
        loading
          ? (
            <Image
              src={IconLoading}
              alt='loading'
              className='w-22 h-22'
              unoptimized
            />
          )
          : children
      }
    </button>
  )
}
// 首页
export const homepage = '/'

// 合作共赢
export const cooperation = '/cooperation'

// 高端定制
export const custom = '/custom'

// 产品介绍
export const product = '/product'

// 关于我们
export const about = '/about'

// 联系我们
export const contact = '/contact'

// 人力外派
export const staffOutsourcing = '/staff-outsourcing'